.tabela-desvio {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 4px;
  margin: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  color: black;
  font-size: 15px;

  > strong {
    text-align: center;
  }

  .cell-desvio {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 calc(100% / 3);
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    padding: 4px;
  }
}
