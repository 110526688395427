body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.layout {
  height: 100vh;
  background-color: #fafafa;
  overflow-y: hidden;
}

.layout-content {
  background-color: white;
}

.layout-header {
  background: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  font-size: 20px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  color: #333333;
  display: flex;
  justify-content: space-between;

  margin-bottom: 2px;
  padding-left: 16px;
}

.logo-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    padding: 24px;
  }
}

.ant-radio-group > .ant-radio-button-wrapper:hover {
  color: #138183;
}

.ant-radio-button-wrapper-checked {
  background: #138183 !important;
  border-color: #138183 !important;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover {
  color: #fff;
  background: #15a1a4 !important;
  border-color: #15a1a4 !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  box-shadow: -1px 0 0 0 #138183 !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #38c1e5 !important;
}

.ant-radio-inner::after,
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #38c1e5 !important;
}

.ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot,
.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #38c1e5 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #38c1e5;
  border-color: #38c1e5;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #20c6f2;
}

.checkbox-footer .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0f79b2;
  border-color: #0f79b2;
}

.checkbox-footer .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.checkbox-footer .ant-checkbox:hover .ant-checkbox-inner,
.checkbox-footer .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #40a9ff;
}

.ant-select-selection--multiple .ant-select-selection__choice {
  color: #ffffff;
  background-color: #138183;
}

.ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: #fafafa;
}

.ant-steps-item-title {
  font-weight: 300;
  color: rgba(0, 0, 0, 0.45) !important;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: #0f79b2;
  box-shadow: none;
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background-color: #0f79b2;
  box-shadow: none;
}

.primary-btn {
  background-color: #0f79b2;
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  min-width: min-content;
}

.secondary-btn {
  background-color: white;
  color: #0f79b2;
  border-color: #0f79b2;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  min-width: min-content;
  &:hover {
    background-color: white;
    color: #40a9ff;
  }
}

.ant-menu-dark .ant-menu-submenu-selected {
  background-color: #1890ff;
}

.ant-form-explain {
  font-size: 13px;
}
